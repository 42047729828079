.input{
  &__label{
    font-weight: bold;
  }
  &__labelcc{
    color: blue;
    cursor: pointer;
    text-decoration-line: underline;
  }
}
._align{
  margin-bottom: 1em;
}

.tooltip-inner {
  white-space:pre-wrap;
  text-align:left;
}