.ui.selection.dropdown .menu > .item {
    font-size: 12px !important;
}

.ui.multiple.search.dropdown,
.ui.multiple.search.dropdown > input.search {
    font-size: 11px !important;
}

#email-lower {
    text-transform: lowercase;
}

.ant-transfer-list {
    width: 50% !important;
}

// .ui.grid > .column:not(.row),
// .ui.grid > .row > .column {
//     width: 400px !important;
// }
// .ui.fluid.input > input {
//     width: 400px !important;
// }
// .ui.fluid {
//     width: 400px !important;
// }

.ant-modal-confirm .ant-modal-confirm-btns {
    text-align: center !important;
    float: none !important;
}
.ant-modal-confirm .ant-modal-body {
    padding: 0 0 32px 0 !important;
}

.hr-popover {
    margin-top: 0 !important;
}
// .ant-btn {
//     color: #fff !important;
//     background-color: #ff0000 !important;
// }

.ant-empty-description {
    display: none;
}
.ant-transfer-list {
    height: 350px !important;
}

.row-item-detail {
    align-items: center;
    margin-top: 1rem;
    margin-bottom: 1rem;
}

.bold {
    font-weight: bold;
}

.listItemContainer > .MuiExpansionPanel-root:before {
    background-color: transparent !important;
}

.listItemContainer .MuiExpansionPanelSummary-root {
    padding: 0;
}

.padding-0 {
    padding: 0;
}

.input-form {
    margin-top: 1rem;
}

.item-resume-form {
    margin-top: 5rem;
}

.item-resume-date-title {
    margin-top: 2rem;
}

.title-forms {
    font-size: 20px;
    line-height: 30px;
    font-weight: bold;
}

.po-form-label {
    font-size: 14px;
    font-weight: 700;
    line-height: 17px;
    letter-spacing: 0.02em;
}

.po-form-value {
    font-size: 14px;
    font-weight: 400;
    line-height: 17px;
    letter-spacing: 0.01em;
}