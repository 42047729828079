@charset "UTF-8";
/**
* Estilos para la tabla de Eventos que requirerend de atención (deprecated)
*/
.tbody {
  height: 200px;
  overflow-y: auto;
  width: 100%; }

.rows {
  display: block;
  float: left;
  padding: 0 0 1em 0; }
  .rows > img {
    margin-right: .5em; }

.head_title {
  color: black; }

.myCard {
  box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.4) !important; }

.myCard2 {
  box-shadow: 0 0px 3px 0 rgba(0, 0, 0, 0.4) !important; }

._table_content {
  position: relative;
  top: -2em !important; }

.equalsWidth {
  height: 28em; }

.py44 {
  margin-top: 5.5rem; }
