
.disabledbutton {
    pointer-events: none;
    // opacity: 0.2;
}

#scroll{
    // border:1px solid;
    height:180px;
    // width:300px;
    overflow-y:scroll;
    overflow-x:hidden;
}

.ui.grid {
    margin-top: 0rem;
}

.disabledComponent {
    pointer-events: none;
	// cursor: not-allowed !important;
    // opacity: 0.2;
}