.headerModal{
  background-color: #007ab9 !important;
  color: white !important;  
}
.iconHeader{
  float: right;
  &:hover{
    cursor: pointer;
  }
}
// Toast styles
.black-background{
  background-color: rgba(9, 139, 220, 1);
  // #007ab9
}

.fancy-bar{
  background-color: rgba(9, 175, 220, 1);
}

.grow-font{
  font-size: 1.3em;
  color: white;
}

.actions_edit_op{
  display: flex !important;
  justify-content: center !important;
}
.modals.dimmer[class*="top aligned"] .modal {
  margin:6vh auto !important;
}
.actions_modalSelect{
  background-color: #fff !important;
}

.circule{
  width: 90px;
  height: 90px;
  border-radius: 50%;
  background:#f8f6f600;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  margin-right: 60px;
  border-style: dotted;
  border-color: #e1d8d8;
}