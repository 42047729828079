._container_left{
  // padding-left: 5em;
  // margin-left: 1px;
}
._container_right{
}
.__left{
  margin-top: 1em;
  margin-left: 1.5em;
}
.__viewDetails{
  margin-top: 1em;
  margin-bottom: 2em;
}
.btn_redirect{
  margin-bottom:1em;
}