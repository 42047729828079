.alignleft {
  float: left;
}

.ui.header.Modal.advanced.search{
  background: linear-gradient(to right, #007ab9 0%, #00558c 100%);
  color: white;
}

.header.Modal.Remove.Document{
  background: linear-gradient(to right, #007ab9 0%, #00558c 100%);
  color: white;
}

#delete_document > button.close{
  //background-color: white !important;
  color: white !important;
  opacity: .6;
}

.ui.Modal.body{
  bottom: auto;
  position: center;
  justify-content: center;
}

.ui.Modal.body.order.list{
  bottom: auto;
  position: center;
  justify-content: center;
}

.ui.Modal.body.booking.list{
  bottom: auto;
  width: 1400px;
  max-width: 100%;
  position: center;
  justify-content: center;
}

.ui.Modal.body.shipment.list{
  bottom: auto;
  width: 1400px;
  max-width: 100%;
  position: center;
  justify-content: center;
}

.ui.button.search{
  background: #007ab9;
  color: white;
  font-weight: lighter;
  width: 40%;
}

.ui.button.cancel{
  font-weight: lighter;
  width: 40%;
  
} 

.ui.filter.header{
  width: 22px;
  height: 22px;
  margin-right: 10px;
}

.ui.filter.body{
  width: 22px;
  height: 22px;
  margin-right: 5px;
}

.ui.Modal.actions{
  background-color: #fff;
}

.alignright {
  float: right;
  top: 15%;
  cursor: pointer;
}