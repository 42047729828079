.qick_view_component {
  float: right;
}
._comments_icon{
  font-size: .6em !important;
}
.container_icon_comment{
  cursor : pointer;
}
.close_icon{
  margin-top: .2em;
  float: right;
  cursor: pointer;
}
.header_comments{
  margin-top: .5em;
  float: left;
  font-size: 1.1em;
}
.comments_containers{
  height: 40em;
}
.close_container{
  position: absolute;
  right: -.6em;
  margin-bottom: .5em;
}

.jss1 .MuiInputBase-input.MuiAutocomplete-input {
  font-size: 12px !important;
}

.createUsers-textfield-13 .MuiInputBase-input.MuiAutocomplete-input {
  font-size:  12px !important;
}

.comments_table_container{
  height: 50em !important;
}
div.ui.segment{
  background: #fff;
  -webkit-box-shadow: 0 1px 2px 0 rgb(255, 255, 255) !important;
  box-shadow: 0 1px 2px 0 rgba(255, 255, 255, 255) !important;
  margin: 1rem 0;
  padding: 1em 1em;
  border-radius: .28571429rem;
  border: 1px solid rgba(255, 255, 255, 255) !important;
}

.st0{fill:#94633A;}
.st1{fill:#D1924F;}
.st2{opacity:0.15;}
.st3{fill:#FFC869;}
.st4{fill:#A66B39;}
.st5{fill:#9C3663;}
.st6{fill:#91B0BA;}
.st7{fill:#FFFFFF;}
.st8{fill:#6F8080;}
.st9{fill:#BFD4DB;}
.st10{fill:#F0EFD2;}
.st11{fill:#B07C4E;}
.st12{fill:#735133;}
.st13{fill:#EDBA62;}
.st14{fill:#ADC7C7;}
.st15{fill:#B2CBD4;}
.st16{fill:#D3E3E8;}
.st17{fill:none;stroke:#232E38;stroke-width:2;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;}
.st18{fill:#81D100;}
.st19{fill:#CAFF0A;}
.st20{fill:#4185E3;}
.st21{fill:#D4F4FF;}
.st22{fill:#55BE07;}
.st23{fill:#BACCD6;}
.st24{fill:none;}
.st25{fill:#A9BDC7;}
.st26{opacity:0.2;fill:#313652;}
.st27{fill:#FCFCFC;}
.st28{fill:#DAE3E6;}
.st29{fill:#E45544;}
.st30{fill:#FFCC00;}
.st31{fill:#383128;}
.st32{fill:#009CEB;}
.st33{fill:#EFF9FC;}
.st34{fill:#5386A1;}
.st35{fill:#E3F3FF;}
.st36{fill:#58CAEF;}
.st37{fill-rule:evenodd;clip-rule:evenodd;fill:#FF6D55;}
.st38{fill-rule:evenodd;clip-rule:evenodd;fill:#F7485E;}
.st39{opacity:0.2;fill:#183945;}
.st40{fill:#FF5430;}
.st41{fill:#F2AD00;}
.st42{fill:#967200;}
.st43{fill:#FFDD00;}
.st44{fill:#A3AFB5;}
.st45{fill:#CADEDE;}
.st46{fill:#E2EDF0;}
.st47{opacity:0.15;fill:#00162B;}
.st48{fill:#D1DBDE;}
.st49{fill:#A6BFBF;}
.st50{fill:#738394;}
.st51{fill:#C5D9D9;}
.st52{fill:#58C8ED;}
.st53{fill:#C2C2C2;}
.st54{fill:#6FCC33;}
.st55{fill:#6B7A8A;}
.st56{fill:#5DB324;}
.st57{fill:#FF6D55;}
.st58{fill:#76D03B;}
.st59{fill:#39C2EF;}
.st60{fill:#4F5B66;}
.st61{opacity:0.1;}
.st62{fill:#0F0605;}
.st63{fill:#1EB7E8;}
.st64{fill:#A0EB60;}
.st65{fill:#232E38;}
.st66{fill:#718A8C;}
.st67{fill:#5D7173;}
.st68{opacity:0.14;fill:#183945;}
.st69{fill:#E32763;}
.st70{fill:#FFBB00;}
.st71{fill:#E88F00;}
.st72{fill:#FFF3C4;}
.st73{fill:#F74848;}
.st74{fill:#FFCABA;}
.st75{fill-rule:evenodd;clip-rule:evenodd;fill:#FFFFFF;}
.st76{fill:#43C5EF;}
.st77{fill:#D4FFFF;}
.st78{fill:#CFFFA6;}
.st79{fill:#FFC800;}
.st80{fill:#5D7A85;}
.st81{fill:#B7C9C9;}
.st82{fill:#BFDBFF;}
.st83{fill:#83939C;}
.st84{fill:#C2CCCC;}
.st85{fill:#D1348F;}
.st86{fill:#FFB9F1;}

#Layer_1_close{
  enable-background:new 0 0 90 90;
}
#Layer_1_trash{
  margin-right: 2em !important;
}

.colorRed{
  color: red;
}

.colorGreen{
  color: green;
}

.fs-16 {
  font-size: 16px !important;
}

.date-picker-po-items div input {
  font-size: 12px !important;
}

.listItemHeader {
  align-items: center;
  display: flex;
}

.listItemModal .modal-content {
  border-radius: 0.6rem;
}

.fs14 {
  font-size: 14px !important;
}

.bgCancel {
  background: #9d9d9c !important;
}

.bgAccept {
  background: #5d9cec !important;
}

.normal {
  font-weight: normal !important;
}

.eventContainer {
  display: flex;
  flex-direction: column;
  gap: 6rem;
}
