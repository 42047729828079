
#fakebox-input{
  bottom: 0;
  box-sizing: border-box;
  left: 2em;
  padding-inline-start: 20px;
  position: absolute;
  top: .3em;
  width: 77%;
  border: none !important;
  font-size: 1em !important;
  // background-color: red !important;

  -webkit-writing-mode: horizontal-tb !important;
  text-rendering: auto;
  color: initial;
  letter-spacing: normal;
  word-spacing: normal;
  text-transform: none;
  text-indent: 0px;
  text-shadow: none;
  // display: inline-block;
  text-align: start;
  -webkit-appearance: textfield;
  background-color: white;
  -webkit-rtl-ordering: logical;
  cursor: text;
  margin: 0em;
  font: 400 11px system-ui;
  padding: 1px;
  border-width: 2px;
  border-style: inset;
  border-color: initial;
  border-image: initial;

}
#fakebox{
  background: white;
  border-radius: 22px;
  box-shadow: 0 1px 6px 0 rgba(32, 33, 36, .28);
  box-sizing: border-box;
  cursor: text;
  font-size: 16px;
  // height: 50px;
  height: 3.2em;
  margin: 0 auto;
  max-width: 584px;
  opacity: 1;
  position: relative;
  transition: none;
}


/*=============================================
=            Icons Styles                     =
=============================================*/

#fakebox-send{
  float: right;
  background: url('data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiA/PjwhRE9DVFlQRSBzdmcgIFBVQkxJQyAnLS8vVzNDLy9EVEQgU1ZHIDEuMS8vRU4nICAnaHR0cDovL3d3dy53My5vcmcvR3JhcGhpY3MvU1ZHLzEuMS9EVEQvc3ZnMTEuZHRkJz48c3ZnIGlkPSJMYXllcl8xIiBzdHlsZT0iZW5hYmxlLWJhY2tncm91bmQ6bmV3IDAgMCAzMC4yIDMwLjE7IiB2ZXJzaW9uPSIxLjEiIHZpZXdCb3g9IjAgMCAzMC4yIDMwLjEiIHhtbDpzcGFjZT0icHJlc2VydmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiPjxzdHlsZSB0eXBlPSJ0ZXh0L2NzcyI+LnN0MHtmaWxsOm5vbmU7c3Ryb2tlOiM0MTQwNDI7c3Ryb2tlLXdpZHRoOjEuMjU7c3Ryb2tlLWxpbmVjYXA6cm91bmQ7c3Ryb2tlLWxpbmVqb2luOnJvdW5kO3N0cm9rZS1taXRlcmxpbWl0OjEwO30uc3Qxe2ZpbGw6bm9uZTtzdHJva2U6IzQxNDA0MjtzdHJva2Utd2lkdGg6MS4xNzEzO3N0cm9rZS1saW5lY2FwOnJvdW5kO3N0cm9rZS1saW5lam9pbjpyb3VuZDtzdHJva2UtbWl0ZXJsaW1pdDoxMDt9PC9zdHlsZT48cGF0aCBjbGFzcz0ic3QwIiBkPSJNMi4xLDE0LjZDOC45LDEyLDI4LjUsNCwyOC41LDRsLTMuOSwyMi42Yy0wLjIsMS4xLTEuNSwxLjUtMi4zLDAuOGwtNi4xLTUuMWwtNC4zLDRsMC43LTYuN2wxMy0xMi4zbC0xNiwxMCAgbDEsNS43bC0zLjMtNS4zbC01LTEuNkMxLjUsMTUuOCwxLjQsMTQuOCwyLjEsMTQuNnoiLz48L3N2Zz4=') no-repeat center !important;
  background-size: 16px 16px;
  bottom: 0;
  cursor: pointer;
  margin-inline-end: 16px;
  padding: 22px 12px 0;
  position: absolute;
  right: .5em;
  top: 0;
  width: 1.5em;
  
  background: transparent;
  border: 0;
  margin: 0;
  padding: 0;
}
#fakebox-send2{
  // background-size: 16px 16px;
  // bottom: 0;
  background-color: red;
  cursor: pointer;
  // margin-inline-end: 16px;
  // padding: 22px 12px 0;
  // position: absolute;
  // right: 2.2em;
  // top: 0;
  // width: 1.5em;
  
  // background: transparent;
  // border: 0;
  // margin: 0;
  // padding: 0;
}
.options_container{
  float:left;
}
.main_container_opt{
  margin-bottom: 2em;
}
._error_message{
  text-align: initial;
  color: #ff5c60;
  margin-top: .8em;
  font-size: .85em;
}

.container-icons{
  display: flex;
  flex-wrap: wrap;
  flex-direction: row-reverse;
}