.body_card{
  overflow:auto;
  height:38rem;
}
._card_comments{
  box-shadow: 0 8px 16px 0 rgba(0,0,0,0.2) !important;
  padding: .5em;
}

.comment_type{
  font-weight:bold !important;
  font-size: .8em;
}
.comment_content{
  font-size: .9em;
}
.user_footer{
  float: right;
  color: #bbbcbe;
  margin-top:1em;
  font-size: .9em;
}
.comments_containers{
  height: 36em;
  margin-bottom: 3em !important;
}
.trash_icon{
  margin-right: 1em !important;
}
.footer_comments{
  float: right !important;
}
// Icon Style (New Comments)
.new-comment{
  height: .5em;
  float: right;
}