@font-face {
  font-family: 'myicon';
  src: url('../font/myicon.eot?98642458');
  src: url('../font/myicon.eot?98642458#iefix') format('embedded-opentype'),
       url('../font/myicon.woff2?98642458') format('woff2'),
       url('../font/myicon.woff?98642458') format('woff'),
       url('../font/myicon.ttf?98642458') format('truetype'),
       url('../font/myicon.svg?98642458#myicon') format('svg');
  font-weight: normal;
  font-style: normal;
}
/* Chrome hack: SVG is rendered more smooth in Windozze. 100% magic, uncomment if you need it. */
/* Note, that will break hinting! In other OS-es font will be not as sharp as it could be */
/*
@media screen and (-webkit-min-device-pixel-ratio:0) {
  @font-face {
    font-family: 'myicon';
    src: url('../font/myicon.svg?98642458#myicon') format('svg');
  }
}
*/
 
 [class^="icon--"]:before, [class*=" icon--"]:before {
  font-family: "myicon";
  font-style: normal;
  font-weight: normal;
  speak: never;
 
  display: inline-block;
  text-decoration: inherit;
  width: 1em;
  margin-right: .2em;
  text-align: center;
  /* opacity: .8; */
 
  /* For safety - reset parent styles, that can break glyph codes*/
  font-variant: normal;
  text-transform: none;
 
  /* fix buttons height, for twitter bootstrap */
  line-height: 1em;
 
  /* Animation center compensation - margins should be symmetric */
  /* remove if not needed */
  margin-left: .2em;
 
  /* you can be more comfortable with increased icons size */
  /* font-size: 120%; */
 
  /* Font smoothing. That was taken from TWBS */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
 
  /* Uncomment for 3D effect */
  /* text-shadow: 1px 1px 1px rgba(127, 127, 127, 0.3); */
}
 
.icon--bar_fulllogo:before { content: '\e800'; } /* '' */
.icon--bar_logout:before { content: '\e801'; } /* '' */
.icon--bar_menuicon:before { content: '\e802'; } /* '' */
.icon--bar_notifications:before { content: '\e803'; } /* '' */
.icon--bar_search:before { content: '\e804'; } /* '' */
.icon--bar_usericon:before { content: '\e805'; } /* '' */
.icon--dashboard_booking:before { content: '\e806'; } /* '' */
.icon--dashboard_container:before { content: '\e807'; } /* '' */
.icon--dashboard_po:before { content: '\e808'; } /* '' */
.icon--dashboard_shipment:before { content: '\e809'; } /* '' */
.icon--menu_adminoff:before { content: '\e80a'; } /* '' */
.icon--menu_analyticsoff:before { content: '\e80b'; } /* '' */
.icon--menu_adminon:before { content: '\e80c'; } /* '' */
.icon--menu_analyticson:before { content: '\e80d'; } /* '' */
.icon--menu_bokingoff:before { content: '\e80e'; } /* '' */
.icon--menu_bokingon:before { content: '\e80f'; } /* '' */
.icon--menu_controltoweroff:before { content: '\e810'; } /* '' */
.icon--menu_controltoweron:before { content: '\e811'; } /* '' */
.icon--menu_costmanagementoff_1:before { content: '\e812'; } /* '' */
.icon--menu_customerserviceoff:before { content: '\e813'; } /* '' */
.icon--menu_customerserviceon:before { content: '\e814'; } /* '' */
.icon--menu_dashboardoff:before { content: '\e815'; } /* '' */
.icon--menu_dashboardon:before { content: '\e816'; } /* '' */
.icon--menu_costmanagementoff:before { content: '\e817'; } /* '' */
.icon--menu_porderoff:before { content: '\e818'; } /* '' */
.icon--menu_porderon:before { content: '\e819'; } /* '' */
.icon--menu_trackingoff:before { content: '\e81a'; } /* '' */
.icon--menu_trackingon:before { content: '\e81b'; } /* '' */
.icon--menu_warehousingoff:before { content: '\e81c'; } /* '' */
.icon--menu_warehousingon:before { content: '\e81d'; } /* '' */
.icon--orders_blue:before { content: '\e81e'; } /* '' */
.icon--orders_red:before { content: '\e81f'; } /* '' */
.icon--po_consigneeicon:before { content: '\e820'; } /* '' */
.icon--po_shipmentinfo:before { content: '\e821'; } /* '' */
.icon--po_suppliericon:before { content: '\e822'; } /* '' */
.icon--po_ordersummary:before { content: '\e823'; } /* '' */
.icon--po_titleicon:before { content: '\e824'; } /* '' */
.icon--po_items:before { content: '\e825'; } /* '' */
.icon--po_shiptoicon:before { content: '\e826'; } /* '' */
.icon--po_orderdetails:before { content: '\e827'; } /* '' */
.icon--shipment_po:before { content: '\e828'; } /* '' */
.icon--shipment_mapmarker:before { content: '\e829'; } /* '' */
.icon--glass:before { content: '\e82a'; } /* '' */
.icon--shipment_containers:before { content: '\e82b'; } /* '' */
.icon--shipment_exceptions:before { content: '\e82c'; } /* '' */
.icon--shipment_transport02:before { content: '\e82d'; } /* '' */
.icon--shipment_timelineuncheck:before { content: '\e82e'; } /* '' */
.icon--music:before { content: '\e82f'; } /* '' */
.icon--shipment_timelinecheck:before { content: '\e830'; } /* '' */
.icon--shipment_transport01:before { content: '\e831'; } /* '' */
.icon--mail:before { content: '\e832'; } /* '' */
.icon--shipment_transport03:before { content: '\e833'; } /* '' */
.icon--user:before { content: '\e834'; } /* '' */
.icon--users:before { content: '\e835'; } /* '' */
.icon--th:before { content: '\e836'; } /* '' */
.icon--th-list:before { content: '\e837'; } /* '' */
.icon--th-large:before { content: '\e838'; } /* '' */
.icon--ok:before { content: '\e839'; } /* '' */
.icon--ok-circled:before { content: '\e83a'; } /* '' */
.icon--cancel:before { content: '\e83b'; } /* '' */
.icon--cancel-circled2:before { content: '\e83c'; } /* '' */
.icon--cancel-circled:before { content: '\e83d'; } /* '' */
.icon--plus-circled:before { content: '\e83e'; } /* '' */
.icon--plus:before { content: '\e83f'; } /* '' */
.icon--minus:before { content: '\e840'; } /* '' */
.icon--minus-circled:before { content: '\e841'; } /* '' */
.icon--help-circled:before { content: '\e842'; } /* '' */
.icon--lock-open:before { content: '\e843'; } /* '' */
.icon--lock:before { content: '\e844'; } /* '' */
.icon--download:before { content: '\e845'; } /* '' */
.icon--upload:before { content: '\e846'; } /* '' */
.icon--forward:before { content: '\e847'; } /* '' */
.icon--export:before { content: '\e848'; } /* '' */
.icon--pencil:before { content: '\e849'; } /* '' */
.icon--bell:before { content: '\e84a'; } /* '' */
.icon--trash-empty:before { content: '\e84b'; } /* '' */
.icon--doc:before { content: '\e84c'; } /* '' */
.icon--folder:before { content: '\e84d'; } /* '' */
.icon--folder-open:before { content: '\e84e'; } /* '' */
.icon--phone:before { content: '\e84f'; } /* '' */
.icon--cog-alt:before { content: '\e850'; } /* '' */
.icon--cog:before { content: '\e851'; } /* '' */
.icon--wrench:before { content: '\e852'; } /* '' */
.icon--basket:before { content: '\e853'; } /* '' */
.icon--calendar:before { content: '\e854'; } /* '' */
.icon--left-open:before { content: '\e855'; } /* '' */
.icon--right-open:before { content: '\e856'; } /* '' */
.icon--up-open:before { content: '\e857'; } /* '' */
.icon--down-open:before { content: '\e858'; } /* '' */
.icon--arrows-cw:before { content: '\e859'; } /* '' */
.icon--ccw:before { content: '\e85a'; } /* '' */
.icon--cw:before { content: '\e85b'; } /* '' */
.icon--shuffle:before { content: '\e85c'; } /* '' */
.icon--align-justify:before { content: '\e85d'; } /* '' */
.icon--list:before { content: '\e85e'; } /* '' */
.icon--chart-bar:before { content: '\e85f'; } /* '' */
.icon--floppy:before { content: '\e860'; } /* '' */
.icon--user-1:before { content: '\e861'; } /* '' */
.icon--users-1:before { content: '\e862'; } /* '' */
.icon--user-add:before { content: '\e863'; } /* '' */
.icon--ok-1:before { content: '\e864'; } /* '' */
.icon--cancel-1:before { content: '\e865'; } /* '' */
.icon--ok-circle:before { content: '\e866'; } /* '' */
.icon--cancel-circle:before { content: '\e867'; } /* '' */
.icon--plus-circle:before { content: '\e868'; } /* '' */
.icon--minus-circle:before { content: '\e869'; } /* '' */
.icon--progress-0:before { content: '\e86a'; } /* '' */
.icon--progress-1:before { content: '\e86b'; } /* '' */
.icon--progress-2:before { content: '\e86c'; } /* '' */
.icon--progress-3:before { content: '\e86d'; } /* '' */
.icon--progress-4:before { content: '\e86e'; } /* '' */
.icon--progress-5:before { content: '\e86f'; } /* '' */
.icon--progress-6:before { content: '\e870'; } /* '' */
.icon--progress-7:before { content: '\e871'; } /* '' */
.icon--logout:before { content: '\e872'; } /* '' */
.icon--shipment_routing:before { content: '\e873'; } /* '' */
.icon--shipment_titleicon:before { content: '\e874'; } /* '' */
.icon--link-ext:before { content: '\f08e'; } /* '' */
.icon--phone-squared:before { content: '\f098'; } /* '' */
.icon--left-circled:before { content: '\f0a8'; } /* '' */
.icon--right-circled:before { content: '\f0a9'; } /* '' */
.icon--up-circled:before { content: '\f0aa'; } /* '' */
.icon--down-circled:before { content: '\f0ab'; } /* '' */
.icon--docs:before { content: '\f0c5'; } /* '' */
.icon--mail-alt:before { content: '\f0e0'; } /* '' */
.icon--comment-empty:before { content: '\f0e5'; } /* '' */
.icon--chat-empty:before { content: '\f0e6'; } /* '' */
.icon--download-cloud:before { content: '\f0ed'; } /* '' */
.icon--upload-cloud:before { content: '\f0ee'; } /* '' */
.icon--bell-alt:before { content: '\f0f3'; } /* '' */
.icon--doc-text:before { content: '\f0f6'; } /* '' */
.icon--plus-squared:before { content: '\f0fe'; } /* '' */
.icon--angle-double-left:before { content: '\f100'; } /* '' */
.icon--angle-double-right:before { content: '\f101'; } /* '' */
.icon--angle-double-up:before { content: '\f102'; } /* '' */
.icon--angle-double-down:before { content: '\f103'; } /* '' */
.icon--angle-left:before { content: '\f104'; } /* '' */
.icon--angle-right:before { content: '\f105'; } /* '' */
.icon--angle-up:before { content: '\f106'; } /* '' */
.icon--angle-down:before { content: '\f107'; } /* '' */
.icon--reply:before { content: '\f112'; } /* '' */
.icon--folder-empty:before { content: '\f114'; } /* '' */
.icon--folder-open-empty:before { content: '\f115'; } /* '' */
.icon--code:before { content: '\f121'; } /* '' */
.icon--reply-all:before { content: '\f122'; } /* '' */
.icon--help:before { content: '\f128'; } /* '' */
.icon--attention-alt:before { content: '\f12a'; } /* '' */
.icon--calendar-empty:before { content: '\f133'; } /* '' */
.icon--angle-circled-left:before { content: '\f137'; } /* '' */
.icon--angle-circled-right:before { content: '\f138'; } /* '' */
.icon--angle-circled-up:before { content: '\f139'; } /* '' */
.icon--angle-circled-down:before { content: '\f13a'; } /* '' */
.icon--lock-open-alt:before { content: '\f13e'; } /* '' */
.icon--rss-squared:before { content: '\f143'; } /* '' */
.icon--minus-squared:before { content: '\f146'; } /* '' */
.icon--minus-squared-alt:before { content: '\f147'; } /* '' */
.icon--export-alt:before { content: '\f14d'; } /* '' */
.icon--sort-alt-up:before { content: '\f160'; } /* '' */
.icon--sort-alt-down:before { content: '\f161'; } /* '' */
.icon--male:before { content: '\f183'; } /* '' */
.icon--plus-squared-alt:before { content: '\f196'; } /* '' */
.icon--file-pdf:before { content: '\f1c1'; } /* '' */
.icon--file-word:before { content: '\f1c2'; } /* '' */
.icon--file-excel:before { content: '\f1c3'; } /* '' */
.icon--file-powerpoint:before { content: '\f1c4'; } /* '' */
.icon--file-code:before { content: '\f1c9'; } /* '' */
.icon--sliders:before { content: '\f1de'; } /* '' */
.icon--share:before { content: '\f1e0'; } /* '' */
.icon--bell-off:before { content: '\f1f6'; } /* '' */
.icon--bell-off-empty:before { content: '\f1f7'; } /* '' */
.icon--trash:before { content: '\f1f8'; } /* '' */
.icon--chart-area:before { content: '\f1fe'; } /* '' */
.icon--chart-pie:before { content: '\f200'; } /* '' */
.icon--chart-line:before { content: '\f201'; } /* '' */
.icon--toggle-off:before { content: '\f204'; } /* '' */
.icon--toggle-on:before { content: '\f205'; } /* '' */
.icon--cart-plus:before { content: '\f217'; } /* '' */
.icon--cart-arrow-down:before { content: '\f218'; } /* '' */
.icon--user-plus:before { content: '\f234'; } /* '' */
.icon--user-times:before { content: '\f235'; } /* '' */
.icon--facebook-rect:before { content: '\f301'; } /* '' */
.icon--twitter-bird:before { content: '\f303'; } /* '' */
.icon--googleplus-rect:before { content: '\f309'; } /* '' */
.icon--vkontakte-rect:before { content: '\f30a'; } /* '' */
.icon--skype:before { content: '\f30b'; } /* '' */
.icon--vimeo-rect:before { content: '\f30e'; } /* '' */
.icon--tumblr-rect:before { content: '\f310'; } /* '' */
.icon--linkedin-rect:before { content: '\f312'; } /* '' */
.icon--youtube:before { content: '\f313'; } /* '' */