#firstTh > span > div > p{
  text-align: left !important;
}

#firstTh {
  text-align: left !important;
}

#firstTd {
  text-align: left !important;
}

#firstTd1 {
  text-align: center !important;
}