.card-label{
  font-size: smaller;
  font-weight: bold;
}
.card-value{
  font-size: smaller;
}
.card-items{
  text-align: center;
}
.progress{
  width: 6em;
}