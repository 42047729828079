
.__loader{
  position: relative;
  top: -20em;
  right: 11.6em;
  width: 100%;
  height: 48em !important; 
 }
 .__loader_container{
   position: fixed;
   z-index: 400;
   height: 2em;
   width: 2em;
   overflow: show;
   margin: auto;
   top: 0;
   left: 0;
   bottom: 0;
   right: 0;
 }
 .__loader_container:before {
  content: '';
  display: block;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: radial-gradient(rgba(20, 20, 20,.6), rgba(0, 0, 0, .6));
  background: -webkit-radial-gradient(rgba(20, 20, 20,.6), rgba(0, 0, 0,.6));
  // background: radial-gradient(rgba(94, 94, 94, .6), rgba(94, 94, 94, .6))
  }






.st0{
   fill:none;
   stroke:#45BFEE;
   stroke-width:2;
   stroke-linecap:round;
   stroke-linejoin:round;
}

 svg#Poseidon_Spinner{
  enable-background:new 0 0 85 85;
  width: 15em;
  height: 15em; 

 }