.global-text{
  color: #2b3b57 !important;
  font-size: 13px !important;
  font-weight: 400 !important;
  font-family: "Source Sans Pro", sans-serif !important;
  font-style: normal;
  // margin-left: .8em;
}
.link-options{
  margin-left: .8em;
  text-decoration: none !important;
  @extend .global-text;
}
.container-options{
  display: flex;
  flex-direction: row;
}
.btn-expander{
  border-radius: .2em;
  &:hover{
    background-color: rgba(196, 196, 196, 0.1);
  }
}