.actions-content{
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
}
.assign-action-content{
  margin-bottom: -1em;
}

.table-header-text{
  color: #bdbdbd !important;
}
.cell-table{
  font-family: "Source Sans Pro", sans-serif;
  font-size: 12px;
}
#table-container{
  font-family: "Source Sans Pro", sans-serif;
  font-size: 12px;  
}
.fixed-cell{
  position:absolute;
  // right: .1em;
	width: auto;
  left:0;
  margin-top: 1em;
  margin-right: 3em !important;
  background-color: white;
  border-radius: 1em;
  border-bottom: none !important;

}
div.v1-PaginationT{
  > div.MuiToolbar-root.MuiToolbar-regular{
    display: flex;
    flex-direction: row;
  }
}
.MuiTableCell-head{
  color: #6c757d !important;
}
.MuiToolbar-root.MuiToolbar-regular{
  display: flex;
  flex-direction: row-reverse;
}


.table-cell-content{
  // font-family: 'Source Sans Pro,sans-serif' !important;
  // color: #3d3c3c !important;
  color: #656565 !important;
  font-size: 12px !important;
  font-weight: 400 !important;
  font-family: "Source Sans Pro", sans-serif !important;
}
// .MuiTableCell-root.MuiTableCell-body{
//   // color: #524f4f !important;
//   color: #656565 !important;
//   font-size: 12px !important;
//   font-weight: 400 !important;
//   font-family: "Source Sans Pro", sans-serif !important;
// }
// .MuiTableCell-root{
//   padding: .2em !important;
//   font-size: 1rem !important;
//   font-family: "Source Sans Pro", sans-serif !important;
// }