@media only screen and (min-width: 1686px) {
  .totalCardInfo {
    min-width: 10.8em;
  }
}

@media only screen and (min-width: 1591px) and (max-width: 1685px){
  .totalCardInfo {
    min-width: 140px;
  }
}

@media only screen and (min-width: 1443px) and (max-width: 1590px){
  .totalCardInfo {
    min-width: 126px;
  }
}

.totalCardInfo {
  min-height: 69px;
}

.bg-grayColor{
  background-color: #545454;
}

.noLeftPadding {
  padding-left: 0px !important;
}

.graphcardsize{
  height: 20em !important;
}

.graphcardbody{
  width: 100%;
  height: 100%;
  // max-height: 325px;
  // max-width: 400px;
  // min-width: 250px;
  // min-height: 100px;
  box-shadow: 0 8px 16px 0 rgba(0,0,0,0.2);
}
