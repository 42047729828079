.search-v1-component{
  padding: '2px 4px' !important;
  display: 'flex' !important;
  align-items: 'center' !important;
  width: 40% !important;
  margin-top: .4em !important;
  margin-left: .4em !important;
  margin-bottom: 1em !important;
  border-radius: 1em !important;
  border: 1.3px solid #f0f0f0 !important;
  &:hover{
    border: none;
    box-shadow: 0 0 3px 0 rgb(62, 161, 231); 
  }
}