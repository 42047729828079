._imgdropdown{
  margin-top: -.2em !important;
}

._format_text{
  font-family: "Source Sans Pro";
  font-size: .8rem;
  font-style: normal;
  // color: red !important;
}
._new{
  font-family: "Source Sans Pro";
  font-size: .9rem;
  color: green;
}
.ui.dropdown .menu>.item{
  height: auto;
  padding: .3rem 1rem .3rem 1rem !important;
}
.ui.dropdown .menu .selected.item{
  height: auto;
  padding: .3rem 1rem .3rem 1rem !important;
}

