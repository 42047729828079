.item-desc-container{
  height: 5em;
  width: 100%;
}

.item-extra-info{
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  // div:nth-child(1){
  //   border: 1px solid red;
  // }
  // div:nth-child(2){
  //   border: 1px solid red;
  // }
}

.container-rss{
  padding: 2em;
  &__title{
    color: black;
    // height: 10em !important;
    // border: 1px solid blue;
    &:hover{
      color: black;
    }
  }
  &__content{
    border: 1px solid transparent !important;
    max-height: 9em !important;
  }
  &__extra{
    font-size: .9em !important;
  }
}

.container-rss__image img, .container-dashboard__image img {
  object-fit: contain; 
}

.item-rss{
  // border: 1px solid red !important;
  box-shadow: rgba(0, 0, 0, 0.04) 0px 3px 5px !important;
  padding: 1em !important;
  height: 11em !important;
  margin-bottom: .5em !important;
}
.item-dashboard{
  // border: 1px solid red !important;
  // box-shadow: rgba(0, 0, 0, 0.04) 0px 3px 5px !important;
  // padding: 1em !important;
  height: 6em !important;
  // margin-bottom: .5em !important;
}


.container-dashboard{
  padding: 0em;
  &__title{
    color: black;
    max-height: 1em !important;
    &:hover{
      color: black;
    }
  }
  &__header{
    font-size: .9em !important;
    height: 7em;
    max-width: 20em;
  }
  &__extra{
    font-size: .8em !important;
  }
  &__content{
    // border: 1px solid red !important;
    max-height: 8em !important;
  }
}