/**
+ Variables de configuracion global
*/
.divcenter {
  align-items: center;
  display: flex;
  justify-content: center; }

@font-face {
  font-family: 'MultipleSans Alt II';
  font-style: normal;
  font-weight: 400;
  src: url("./resources/Fonts2/MultipleSansAltII-Regular.eot");
  /* IE9 Compat Modes */
  src: local("MultipleSans Alt II"), local("MultipleSans Alt II"), url("./resources/Fonts2/MultipleSansAltII-Regular.eot") format("embedded-opentype"), url("./resources/Fonts2/MultipleSansAltII-Regular.woff2") format("woff2"), url("./resources/Fonts2/MultipleSansAltII-Regular.woff") format("woff"), url("./resources/Fonts2/MultipleSansAltII-Regular.ttf") format("truetype"), url("./resources//Fonts2/MultipleSansAltII-Regular.svg") format("svg");
  /* Legacy iOS */ }

.material-icons {
  font-family: 'Material Icons' !important; }

.main-close-especial {
  font-family: 'Font Awesome 5 Free' !important; }

.limiter {
  width: 100%;
  margin: 0 auto; }

._input {
  background-color: #272727;
  border: 1px solid transparent;
  color: #fff;
  height: 35px;
  margin: 1px 0 1em;
  outline: none;
  padding: 0 10px;
  width: 102%;
  border-radius: 2em;
  margin-left: -40px; }
  ._input::placeholder {
    color: rgba(212, 212, 212, 0.81); }
  ._input:focus {
    border: 1px solid #fff; }

._input2 {
  background-color: #272727;
  border: 1px solid transparent;
  color: #fff;
  height: 35px;
  margin: 1px 0 1em;
  outline: none;
  padding: 0 10px;
  width: 102%;
  border-radius: 2em; }
  ._input2::placeholder {
    color: rgba(212, 212, 212, 0.81); }
  ._input2:focus {
    border: 1px solid #fff; }

.validInput {
  background-color: #272727;
  border: 1px solid transparent;
  color: #fff;
  height: 35px;
  margin: 1px 0 1em;
  outline: none;
  padding: 0 10px;
  width: 102%;
  border-radius: 2em; }
  .validInput::placeholder {
    color: rgba(212, 212, 212, 0.81); }
  .validInput:focus {
    border: 1px solid #fff; }

.invalidInput {
  background-color: #272727;
  border: 1px solid transparent;
  color: #fff;
  height: 35px;
  margin: 1px 0 1em;
  outline: none;
  padding: 0 10px;
  width: 102%;
  border-radius: 2em; }
  .invalidInput::placeholder {
    color: rgba(212, 212, 212, 0.81); }
  .invalidInput:focus {
    border: 1px solid yellow; }

._alert {
  color: yellow;
  height: 1em;
  margin: 1px 0 1em;
  outline: none;
  padding: 0;
  width: 102%; }

._containerLogin {
  color: #fff;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  align-content: center; }

._rememberMe {
  padding: 2em 0 1em 0; }

.login_check {
  color: #f4f4f4; }

.footer-top {
  flex-grow: 1;
  height: 200px; }
  @media (max-width: 575.98px) {
    .footer-top {
      height: 10px; } }
  @media (max-width: 991.98px) {
    .footer-top {
      height: 180px; } }
  @media (max-width: 575.98px) {
    .footer-top {
      height: 120px; } }
  @media (max-width: 767.98px) {
    .footer-top {
      height: 4em; } }

.page-footer {
  color: #f4f4f4;
  width: 3em; }

.mobile {
  display: inline-block; }

.copyright {
  margin-top: 1em; }

.apple_image {
  width: 80px;
  height: 30px; }

.android_image {
  width: 80px;
  height: 30px; }

.img_logo {
  margin-bottom: 1em; }

._loginButton {
  background: #42ADEC;
  border: none;
  border-radius: 6px;
  color: #fff;
  cursor: pointer;
  height: 2em;
  padding: 0;
  width: 35%; }
  ._loginButton:hover {
    background: #529abe; }

.decorationHover:hover {
  text-decoration: underline; }

._loginButton2 {
  font-style: italic;
  font-weight: bold;
  background: #42ADEC;
  border: none;
  border-radius: 6px;
  color: #fff;
  cursor: pointer;
  height: 35px;
  padding: 0 10px;
  width: 102%;
  border-radius: 2em; }
  ._loginButton2:hover {
    background: #529abe; }

._footerContent {
  text-align: center; }

._loginButtonMore {
  background: #42ADEC;
  border: none;
  border-radius: 12px;
  color: #fff;
  cursor: pointer;
  height: 39px;
  padding: 0;
  width: 49%;
  margin-top: 3em;
  font-size: 15px;
  letter-spacing: 2px; }

.goBack {
  cursor: pointer;
  color: #fff;
  height: 0em;
  margin: 1px 0em 2em 0;
  margin-left: -5em;
  outline: none;
  padding: 0 10px;
  width: 102%; }

.margin_bottom {
  margin-bottom: 3em; }

.login-container2 {
  width: 100%;
  height: auto;
  min-height: 100vh;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  background-repeat: repeat-x; }

.login-container {
  width: 100%;
  height: auto;
  min-height: 100vh;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  padding: 1em;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  background-repeat: repeat-x; }

#dropdown-custom-comments {
  margin-left: -11em !important; }

text.flot-tick-label.tickLabel {
  font-size: smaller;
  max-width: 52px !important; }

#logoTemplateCreate label {
  margin-left: 8em !important; }

p, span, div, a, h1, h2, h3, h4, h5, td, th, table, input, label, td, canvas,
card, ResponsiveBarCanvas, CardsContent, Modal {
  font-family: MultipleSans Alt II !important; }

.pono-header-text {
  font-size: 15px;
  font-weight: bold; }

.pono-header-value {
  color: #1890ff; }

.datesPO {
  padding-left: inherit;
  padding-right: inherit;
  font-style: italic; }
  .datesPO Label {
    color: #4d4d4d; }

.divContenEnd {
  align-items: end;
  display: flex;
  justify-content: end; }

.confirmModalEntities {
  z-index: 1600 !important;
  background-color: rgba(0, 0, 0, 0.1); }

.icon-count-card {
  color: #fff;
  font-size: 2.5em; }

.title-count-card {
  font-size: 12px;
  font-weight: bold;
  color: #fff;
  padding-right: 5px;
  padding-top: 5px; }

.total-count-card {
  font-size: 35px;
  font-weight: bold;
  color: #fff; }

.form-input-switch.ant-switch-checked {
  background-color: #5d9cec !important; }

.date-picker-po-items div input {
  font-size: 12px !important; }

.label-euro {
  font-size: 12px !important; }

.input-euro {
  font-size: 12px !important; }

.MuiFormControl-root.MuiTextField-root {
  width: 100%; }

.columnSwitch {
  display: flex;
  flex-direction: column; }

.widthAuto {
  width: fit-content; }

.ui.pointing.secondary.wrapped.menu {
  border-bottom: none !important; }

.titleCard {
  font-weight: bold;
  font-size: 12px;
  line-height: 17px;
  color: black;
  letter-spacing: 0.02em;
  font-style: normal; }
