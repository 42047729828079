.borderTd {
  border: 1px solid black;
  border-collapse: collapse;
  text-align: center;
  z-index: 0;
}

.columnHeaders{
  min-width: 80px;
  text-align: center;
  font-weight: bold;
  color: #51647E;
  border-bottom: 1px solid black;
  border-right: 1px solid black;
}

.absoluteTd {
  font-weight: bold;
  position: sticky;
  width: 100px;
  left: 0;
  z-index: 1;
  top: auto;
  background: #F5F5F5;
  border-bottom: 1px solid #F5F5F5;
  box-shadow: 0 2px 1px 1px #F5F5F5;
}

.portEvent{
  height: 25px;
  width: 25px;
  border-radius: 50%;
  display: inline-block;
}

.insidePortEvent {
  margin-top: 2px;
  color: white;
}

.containerScheduler {
  display: inline-block;
  box-sizing: border-box;
  white-space: nowrap;
}

.activeColumn {
  background: #E2E2E2;
}

.activeColumnHeader {
  background: #87CCCA;
}

.activeData {
  background: #E2E2E2;
}

.activePortHeader {
  background: #87CCCA;
}

.disabledData {
  opacity: 0.5;
  z-index: -1;
}

.Select-menu-outer{
  z-index: 2;
}
