.circleGreen > span > span > svg {
  color: green !important;
  font-size: 15px !important;
  margin-top: 5px !important;
}

.MuiStepIcon-root.MuiStepIcon-completed {
    color: #1943a7 !important;
  }

.circleMiddle {
  color: green !important;
  width: 10px !important;
  z-index: 1;
  position: absolute;
}

.tooltipNew {
    position: relative;
    display: inline-block;
    border-bottom: 1px dotted black;
}
.tooltipNew .tiptext {
    visibility: hidden;
    width: 120px;
    background-color: black;
    color: #fff;
    text-align: center;
    border-radius: 3px;
    padding: 6px 0;
    position: absolute;
    z-index: 1;
    box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);
}
.tooltipNew .tiptext::after {
    content: "";
    position: absolute;
    border-width: 5px;
    border-style: solid;
}
.tooltipNew:hover .tiptext {
    visibility: visible;
}

.tooltipNew.topNew .tiptext{
    margin-left: -55px;
    bottom: 150%;
    left: 50%;
}
.tooltipNew.topNew .tiptext::after{
    margin-left: -5px;
    top: 100%;
    left: 50%;
    border-color: #2E2E2E transparent transparent transparent;
}

.tooltipNewVert {
    position: relative;
    display: inline-block;
    bottom: 50px;
    left: 7px;
}
.tooltipNewVert .tiptextVert {
    visibility: hidden;
    width: 120px;
    background-color: black;
    color: #fff;
    text-align: center;
    border-radius: 3px;
    padding: 6px 0;
    position: absolute;
    z-index: 1;
    box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);
}
.tooltipNewVert .tiptextVert::after {
    content: "";
    position: absolute;
    border-width: 5px;
    border-style: solid;
}
.tooltipNewVert:hover .tiptextVert {
    visibility: visible;
}

.tooltipNewVert.leftVert .tiptextVert{
    top: -15px;
    right: 110%;
}
.tooltipNewVert.leftVert .tiptextVert::after{
    margin-top: -15px;
    top: 50%;
    left: 100%;
    border-color: transparent transparent transparent #2E2E2E;
}

.disabledBorderLeft > div > div {
  border-left: none;
  margin-left: 0px !important;
  padding-left: 10px !important;
}

.disabledCircle > span > span > svg {
  color: rgba(0, 0, 0, 0.38) !important;
}

.disabledCircle > span > span > svg > text {
  // color: rgba(0, 0, 0, 0.38) !important;
  // fill: none !important;
}
