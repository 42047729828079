.buttonTable{
  width: 100%;
  display: block;
}

.addAll{
  color: #2B98F0;
  cursor: pointer;
}

.saveButton.MuiButton-contained{
  color: white;
  background: #2B98F0;
}

.deleteAll{
  color: #F17A73;
  cursor: pointer;
}

.addCircle{
  cursor: pointer;
  height: 60px !important;
  width: 60px !important;
  border-radius: 50%;
  display: inline-block;
  border: solid 1px;
}

.addCircleInside{
  margin-top: 17px;
  color: grey;
}

.customSmall{
  width: 25px !important;
  height: 25px !important;
  min-height: 25px !important;
  margin-top: 10px !important;
}

.customLarge{
  width: 45px !important;
  height: 45px !important;
  min-height: 25px !important;
  margin-top: 10px !important;
}

.emailInput{
  margin: 5px 10px 5px 10px;
  width: 60%;
}

.scrollUsers{
  max-height: 500px;
  overflow-y: auto;
}

.MuiRadio-colorRadio.Mui-checked{
  color: #0079B9 !important;
}

.checkCustom.Mui-checked{
  color: #0079B9 !important;
}

.pReport{
  margin-top: 10px !important;
  margin-right: 40px;
  font-weight: bold;
}

.pInput{
  margin-top: 10px !important;
  font-weight: bold;
}

.scrolleableDiv{
  max-height: 174px;
  overflow-y: scroll;
}

.chipSelectedFields{
  margin: 10;
}
