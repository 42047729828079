.dot {
  width: 10px; /* Ancho del punto */
  height: 10px; /* Altura del punto */
  border-radius: 50%; /* Hacer que el punto sea redondo */
  display: inline-block;
}

.bg-green {
  background-color: #00a65a;
}

.bg-red {
  background-color: #dd4b39;
}

.detailModalHeader {
  border-bottom: 2px solid #50A2DF;
  margin-left: 2rem;
  margin-right: 2rem;
  padding-bottom: 0.5rem;
  padding-left: 0.5rem;
  padding-top: 2rem;
}

.noPaddingTop {
  padding-top: 0;
}

.marginTopBottom {
  margin-top: 1rem;
  margin-bottom: 1rem;
}

.marginLeftMinus {
  margin-left: -1rem;
}

.tableMargin {
  margin-right: 3rem;
  margin-top: 1rem;
  margin-left: -1rem;
  border-bottom: none !important;
}

.tableMargin th {
  font-size: 12px;
  font-weight: bold !important;
  background: white !important;
  padding: 12px !important;
  color: #2b3b57 !important;
  text-align: center !important;
}

.tableMargin .ant-table-cell::before {
  content: none !important;
  background-color: none !important;
}

.tableMargin tr:nth-child(odd) {
  background-color: #FAFBFC !important;
}

.tableMargin tr:nth-child(even) {
  background-color: #ffffff !important;
}

.tableMargin tr:nth-last-child(1) td {
  border-bottom: none !important;
}

.tableMargin td {
  font-size: 12px !important;
  padding: 12px !important;
  color: #2b3b57 !important;
  text-align: center !important;
}

.tableMargin td:hover {
  cursor: pointer;
}

.gridTable th {
  text-align: left !important;
}

.gridTable td {
  text-align: left !important;
  max-width: none !important;
}

.pagination-table {
  margin: 1rem;
  justify-content: center;
  display: flex;
}

.confirmModalEntities {
  z-index: 1600 !important;
  background-color: rgba(0,0,0,0.1);
}

.no-clickeable table * {
  cursor: default !important;
}