.headerTable {
    text-align: center !important;
    color: #888 !important;
    font-size: 12px !important;
}

.columsTable {
    font-size: 12px;
    font-weight: 500;
}

.k-button-solid-primary{
    border-color: #1274AC;
    color: white;
    background-color: #1274AC;
    background-image: linear-gradient(rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.02));;
}
.k-button-md {
    padding-block: 4px;
    padding-inline: 8px;
    font-size: 14px;
    line-height: 1.4285714286;
    cursor: pointer;
}
  .k-rounded-md {
    border-radius: 4px;
  }

  .k-table, .k-data-table {
    color: #424242;
  }

  .k-button-solid-base {
    border-color: rgba(0, 0, 0, 0.08);
    color: #424242;
    background-color: #f5f5f5;
    background-image: linear-gradient(rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.02));
  }

  .btn-width-table {
    width: 5em;
  }