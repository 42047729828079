.MuiStepIcon-root.MuiStepIcon-active {
  color: lightgray !important
}

// .MuiStepIcon-text {
//   fill: lightgray !important
// }

.MuiStepContent-root {
  // border-left: 0px !important;
}

.MuiStepLabel-label.MuiStepLabel-active {
  color: #656565 !important
}

.table-td{
  min-width: 210px;
}

table > thead > tr > th > span > div {
  width: 100% !important;
}

.clickeable:hover {
  text-decoration: underline;
  cursor: pointer;
  font-size: 12px;
}
