._inputFilters{
  margin-bottom: 1em;
}

.imggggg {
  vertical-align: middle;
  border-style: none;
  height: 32px!important;
  margin: 7px;
}
.imgggggg {
  vertical-align: middle;
  border-style: none;
  height: 32px!important;
  margin: 4px;
  margin-top: 1px;
}

.Invoicing{
  font-size: 30px;
  font-Weight:'bold'
}

.imgggggg1 {
vertical-align: middle;
border-style: none;
height: 32px !important;
margin: 19px;
margin-top: 2px;
}