/* .btn-success {
    color: #fff;
    background-color:  #D41C29;
    border-color:  #D41C29;
  } */

/* .ui.red.button, .ui.red.buttons .button {
    background-color: #1c28d4;
    color: #fff;
    text-shadow: none;
    background-image: none;
}

.btn-success.focus, .btn-success:focus {
	color: #fff;
	background-color: #214a88;
	border-color: #1e2c7e;
} */

.input__labelcc {
	color: #0042ca;
	cursor: pointer;
}

.btn_close_ticket_project_1 {
	background-color: #0374B3 !important;
	color: white !important;
}

.btn_close_ticket_project_2 {
	background-color: #D41C29 !important;
	color: white !important;
}

.btn_close_ticket_project_3 {
	background-color: #D41C29 !important;
	color: white !important;
}

.btn_close_ticket_project_4 {
	background-color: #487629 !important;
	color: white !important;
}

.btn_close_ticket_project_6 {
	background-color: #D41C29 !important;
	color: white !important;
}

.back-button-sd {
	font-size: 18px !important;
	font-weight: bold;
	text-decoration: none;
	cursor: pointer !important;
}

.title-ticket {
	font-size: 18px;
	font-weight: bold;
}