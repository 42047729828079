
.filepond--drop-label.filepond--drop-label label {
    margin-left: 0px !important;
}

.filepond--root .filepond--credits[style] {
    display: none !important;
}


