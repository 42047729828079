.table-sticky>thead>tr>th,
.table-sticky>thead>tr>td  {
	background: #fff;
	/* color: #fff; */
	top: 0px;
	position: sticky;
}

.modal-actions {
    background-color:#fff !important;
    margin-top: 23px;
}

.table-height {
	height: 320px;
	display:block;
	width: 100%;
	overflow-x: scroll;
}

.table-body{
	/* display: block; */
	/* max-height: 300px; */
	/* width:100% */
}

.colorText{
cursor: pointer;
color: #5d9cec;
}

.pointer{
	cursor: pointer;
	}

.headerModalMulti{
	font-size: 19px !important;
}
.rdtPicker {
	width: 274px !important
}

.flex-container2 {
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: row;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-flex-wrap: nowrap;
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
    -webkit-justify-content: flex-end;
    -ms-flex-pack: end;
    justify-content: flex-start;
    -webkit-align-content: stretch;
    -ms-flex-line-pack: stretch;
    align-content: stretch;
    -webkit-align-items: flex-start;
    -ms-flex-align: start;
    align-items: flex-start;
	}

.flex-container {
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: row;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-flex-wrap: nowrap;
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
    -webkit-justify-content: flex-end;
    -ms-flex-pack: end;
    justify-content: flex-end;
    -webkit-align-content: stretch;
    -ms-flex-line-pack: stretch;
    align-content: stretch;
    -webkit-align-items: flex-start;
    -ms-flex-align: start;
    align-items: flex-start;
	}

	.flex-containerChip {
		display: -ms-flexbox;
		display: -webkit-flex;
		display: flex;
		-webkit-flex-direction: row;
		-ms-flex-direction: row;
		flex-direction: row;
		-webkit-flex-wrap: nowrap;
		-ms-flex-wrap: nowrap;
		flex-wrap: nowrap;
		-webkit-justify-content: flex-end;
		-ms-flex-pack: end;
		justify-content: start;
		-webkit-align-content: stretch;
		-ms-flex-line-pack: stretch;
		align-content: stretch;
		-webkit-align-items: flex-start;
		-ms-flex-align: start;
		align-items: flex-start;
		}

.table {
	border-collapse: collapse;
	border-spacing: 0;
}

.table-bordered>thead>tr>th,
.table-bordered>tbody>tr>th,
.table-bordered>thead>tr>td,
.table-bordered>tbody>tr>td {
	border: 1px solid #ddd;
}

.table-td{
	// min-width: 80px !important;
	// width: 120px !important;
	font-size: 12px;
	text-align: center;
}


#firstTh > span > div > p{
  text-align: left !important;
}

// #firstTd {
//   text-align: left !important;
// }

.quick_view_component{
	margin-right: 1em;
	// float: left;
	background-color: blue;
	// display: inline;
}
.excel_icon{
	margin-left: 3em !important;
	float: right;
}
.buttonExter{
	margin-top: -5px;
}
.drop_content{
	float: right;
}

#bgBlue {
	background: #0079B9;
	color: white;
}

.thick-rows{
	padding: .9em !important;
}
.thin-rows{
	padding: .4em !important;
}
.zui-scroller {
	margin-left: 7.3em;
	overflow-x: auto;
	width: 92%;
}
.col-fixed {
	left: 0;
	position: absolute;
	width: 10em;
	> div {
		margin-top: 1em;
	}
	> div.thead-div {
		margin-top: 0em ;
	}
}
