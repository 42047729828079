// Quita los estilos del login
html, body{
  min-height: 100%;
  min-width: 100%;
  background: #fff;
  position:relative;
}

// ._root_login {
//   // background-color:#202121 !important;
//   height: 100%!important ;
//   width: 100% !important ;
//   background-size: cover !important;
//   background-position: center !important;
//   background-image:  url('https://magic-dome.firebaseapp.com/static/media/Login_BG2.ff9989e7.png') !important
// }

// #root{
//   height: 100%;
// }

// 