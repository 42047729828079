.feeds-container{
  max-height: 37.4em;
  overflow-y: scroll;
  overflow-x: hidden;
  // border: 1px solid red;
}
.statistics-container{
  // display: flex;
  // flex-direction: row;
  // flex-wrap: wrap;
  // justify-content: space-around;
  margin-bottom: .5em;
}
.statistics-item{
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  div:nth-child(1){
    // border: 1px solid red;
    margin-right: 1em;
  }
}