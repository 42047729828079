.myWrapped{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
  }

  .ui.secondary.pointing.menu .active.item {
    border-color: #42ADEC !important;
    color: #42ADEC !important;
}

.container_description{
background: #efecec !important
}

.form-control-sm, select.form-control-sm {
background: #efecec !important
}
