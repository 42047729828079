/**
* Nuevos estilos de la tabla de eventos
*/
.table-wrapper-scroll-y{
  display: block;
  max-height: 23em !important;
  overflow-y: auto;
  -ms-overflow-style: -ms-autohiding-scrollbar;
}
.eventsTableBody{
  color: #878c9a;
}
.event_icon{
  margin:.5em;
}

.hyperLink{
  cursor: pointer;
  &:hover{
    text-decoration: underline;
  }
}