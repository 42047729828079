.margin_left{
  margin-left: -.2em;
}

.shadow{
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.3) !important
}

.endPosition{
  text-align-last: end;
  text-transform:none !important
}