.center{
  text-align: center;
}

._iconHover{
  color:#AFBBBD;  
  &:hover{
    color: #42ADEC;
  }
}

._icondisable{
  color:#AFBBBD;}

._loginNext{
  font-style: italic;
  font-weight: bold;
  background: #42ADEC;
  border: none;
  border-radius: 6px;
  color: #fff;
  cursor: pointer;
  height: 35px;
  padding:0 10px;;
  width: 102%;
  border-radius: 2em;
  // margin-left: -40px;
  &:hover{
    background: #529abe;
  }
}

.bg_resumen_car {
  background-color: #AFBBBD;
  color: #fff !important;
  text-align: initial;
}

.borderImg{
  border: 2px solid #43ADEB;
}

.ant-input-number {
  width: 57px;
  height:31px;
}

.space{
  height: 3em;
}

.scrollGrid{
  overflow-y: auto;
  height: 15em;
  max-height: 23em;
}

.fontBold{
  font-weight: bold;
}

.sizewit{
  width: 10em;
}

.inputAnd{
  height: 36px;
}