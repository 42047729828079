.MuiFab-lightBlue{
  background: #2B98F0 !important;
  color: white !important;
}

.modalTable.visible.transition{
  display: inline-table !important;
}

.descriptionInput{
  width: 90%;
}
