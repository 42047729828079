.drawerSpan{
  margin-top: -5px;
  text-align: left;
  font-size: 11px;
}

.noRight{
  padding-right: 0px !important;
  margin-right: 0px !important;
}

.ui.inverted.menu{
  background: transparent !important;
  box-shadow: none !important;
}
