#Capa_1{
  cursor: pointer;
  enable-background:new 0 0 512 512;
  float: right;
  // background-color: red;
  margin-top: 1em;
  margin-right: 2.2em;
  &:hover{
    
    .active-path{
      fill: #9da1a4;
    }
  } 
}
.active-path{
  fill: #c2c5c7;
}