.table-sticky>thead>tr>th,
.table-sticky>thead>tr>td  {
	background: #fff;
	/* color: #fff; */
	top: 0px;
	position: sticky;
} 

.table-height {
	height: 320px;
	display:block;
	width: 100%;
	overflow-x: scroll;	
}

.table-body{
	/* display: block; */
	/* max-height: 300px; */
	/* width:100% */
}



.table {
	border-collapse: collapse;
	border-spacing: 0;
}

.table-bordered>thead>tr>th,
.table-bordered>tbody>tr>th,
.table-bordered>thead>tr>td,
.table-bordered>tbody>tr>td {
	border: 1px solid #ddd;
}

.table-td{
	min-width: 167px;
}
#firstTh > span > div > p{
  text-align: left !important;
}

// #firstTd {
//   text-align: left !important;
// }
