.__loader_ {
  position: relative;
  top: -20em;
  right: 11.6em;
  width: 100%;
  height: 48em !important;
}
.__loader_container_ {
  position: fixed;
  z-index: 400;
  height: 2em;
  width: 2em;
  overflow: show;
  margin: auto;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background: transparent !important;
}
.__loader_container_:before {
  content: "";
  display: block;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: hsla(0, 0%, 93%, 0.75);
}

.st0 {
  fill: #487629;
}

.st1 {
  fill: none;
  stroke: #487629;
  stroke-width: 6;
  stroke-linecap: round;
  stroke-linejoin: round;
  stroke-miterlimit: 10;
}

//Poseidon

.__loader_container {
  position: fixed;
  z-index: 400;
  height: 2em;
  width: 2em;
  overflow: show;
  margin: auto;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background: transparent !important;
}
.__loader_container:before {
  content: "";
  display: block;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: radial-gradient(rgba(20, 20, 20, 0.6), rgba(0, 0, 0, 0.6));
  background: -webkit-radial-gradient(rgba(20, 20, 20, 0.6), rgba(0, 0, 0, 0.6));
  // background: radial-gradient(rgba(94, 94, 94, .6), rgba(94, 94, 94, .6))
}

.stPoseidon {
  fill: none;
  stroke: #45bfee;
  stroke-width: 2;
  stroke-linecap: round;
  stroke-linejoin: round;
}
svg#Poseidon_Spinner {
  enable-background: new 0 0 85 85;
  width: 15em;
  height: 15em;
}

//Roldan

.__loader_container_Roldan_ {
  position: fixed;
  z-index: 400;
  height: 2em;
  width: 2em;
  overflow: show;
  margin: auto;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background: transparent !important;
}
.__loader_container_Roldan_:before {
  content: "";
  display: block;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: radial-gradient(rgba(20, 20, 20, 0.6), rgba(0, 0, 0, 0.6));
  background: -webkit-radial-gradient(rgba(207, 205, 205, 0.6), rgba(37, 37, 37, 0.6));
}

//Sales Intellifence

.__loader_container_sales {
  position: fixed;
  z-index: 400;
  height: 2em;
  width: 2em;
  overflow: show;
  margin: auto;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
}
.__loader_container_sales:before {
  background-color: none !important;
  content: "";
  display: block;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: radial-gradient(rgba(252, 252, 252, 0.6), rgba(252, 252, 252, 0.6));
}

.stSales {
  fill: none;
  stroke: #0097dc;
  stroke-width: 2;
  stroke-miterlimit: 10;
}

//Magic Dome

.__loader_container__dome_ {
  position: fixed;
  z-index: 400;
  height: 2em;
  width: 2em;
  overflow: show;
  margin: auto;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
}
.__loader_container__dome_:before {
  content: "";
  display: block;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: radial-gradient(rgba(20, 20, 20, 0.6), rgba(0, 0, 0, 0.6));
  background: -webkit-radial-gradient(rgba(20, 20, 20, 0.6), rgba(0, 0, 0, 0.6));
  // background: radial-gradient(rgba(94, 94, 94, .6), rgba(94, 94, 94, .6))
}

.stMagicDome {
  fill: none;
  stroke: #45bfee;
  stroke-width: 2;
  stroke-linecap: round;
  stroke-linejoin: round;
}

.__loader_container_euro {
  position: fixed;
  z-index: 400;
  height: 2em;
  width: 2em;
  overflow: show;
  margin: auto;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background: transparent !important;
}
.__loader_container_euro:before {
  content: "";
  display: block;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: radial-gradient(rgba(20, 20, 20, 0.6), rgba(0, 0, 0, 0.6));
  background: -webkit-radial-gradient(rgba(207, 205, 205, 0.6), rgba(37, 37, 37, 0.6));
}
