.trash_icon{
  float: left !important;
}
.icon_trash_component{
  cursor: pointer;
  height: 18px !important;
  width: 18px !important;
}
.delete_text{
  font-weight: bold;
  font-size: .85em;
}
.btn-delete{
  height: 1em !important;
  line-height: .1em !important;
}