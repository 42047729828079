/**
* Resposive breakpoints
*/
$min_small: 576px;
$min_medium: 768px;
$min_large: 992px;
$min_extra: 1200px;
$max_small: 575.98px;
$max_medium: 767.98px;
$max_large: 991.98px;
$max_extra: 1199.98px;
.card-title{
    cursor: pointer;
}
// Colores de icono de cerrado
$color : #42ADEC;
$color-second: #fff;


._gray_button{
  background-color: gray !important;
  color: #fff !important;
}

// Estilos para el icono de cerrado de imagen
// .close {
//     position: absolute;
//     top: -.5em;
//     right: .1em;
//     width: 32px;
//     height: 32px;
//     opacity: 0.6;
//     border-radius: 5em;
//     background-color: $color-second;
//     &:hover{
//         opacity: 1;
//     }
//     &:before , &:after {
//         position: absolute;
//         left: 15px;
//         content: ' ';
//         height: 33px;
//         width: 2px;
//         background-color: $color;
//     }
//     &:before {
//         transform: rotate(45deg)
//     }
//     &:after{
//         transform: rotate(-45deg)
//     }
//     // Construcción de los media querys
//     //
//     @media(min-width: $min_medium){
//         width: 10;
//         height: 10;
//         position: absolute;
//         background-color: $color-second;
  
//         &:hover{
//             opacity: 1;
//         }
//         &:before , &:after {
//             position: absolute;
//             left: 15px;
//             height: 24px;
//             width: 2.2px;
//             // border-radius: 1em;
//             background-color: $color;
//         }
//         &:before {
//             transform: rotate(45deg)
//         }
//         &:after{
//             transform: rotate(-45deg)
//         }        
//        }

//     // pantallas MAX
//     @media(max-width: $max_medium) {
//         width: 10px;
//         height: 10px;
//         position: absolute;
//         background-color: $color-second;
  
//         &:hover{
//             opacity: 1;
//         }
//         &:before , &:after {
//             position: absolute;
//             left: 15px;
//             height: 24px;
//             width: 2.2px;
//             // border-radius: 1em;
//             background-color: $color;
//         }
//         &:before {
//             transform: rotate(45deg)
//         }
//         &:after{
//             transform: rotate(-45deg)
//         }        
//     }

//    // pantallas pequeñas
//    @media(max-width: $max_small) {
//         width: 40px;
//         height: 40px;
//         right: 130px;
//         position: absolute;
//         border-radius: 5em;
//         background-color: $color-second;
  

//         &:hover{
//             opacity: 1;
//         }
//         &:before , &:after {
//             position: absolute;
//             // left: -140px;
//             height: 30px;
//             width: 4px;
//             border-radius: 1em;
//             background-color: $color;
//         }
//         &:before {
//             transform: rotate(45deg)
//         }
//         &:after{
//             transform: rotate(-45deg)
//         }        
//     }    
//   }



  // boton de subida de datos
  .button-file-upload{
      margin-top: 2em;
  }
  ._responsive{
    border-radius: .5em;
    @media(max-width: $max_small) {
        width: 90px;
        height: 90px;
        border-radius: .5em;
    }
    @media(max-width: $max_extra){
        width: 150px;
        height: 150px;
    }
  }

  // Contenedor de las imagenes
  ._containerImages{
    position: relative;
    
    margin-right: 0em;
  }

/**
*  Styles for POConfirmation
* ------------------------------------------
*/
.footer_confirm{
    margin: 5em -3em 5em -5em;
}
._btn_label{
    position: relative;
    margin-right: 30px;
    margin-bottom: 50px;
    float: right;

}
// Title for value of legend
._legend{
    font-size: 22px;
}

// label for inputs in header of Poconfirmation
._inputLabel{

    margin: 1em 0 0 0;
    justify-content: center;
}
// input view for order confirmations
._views{
    margin: 1em 0 1em 0;
}

// styles for PONumber item
._poNumber{
    margin: 1em 0 1.5em 0;
}
// styles for main container in poconfirmation
._mainContainer{
    padding: .5em 0 0 0;
}

._confirmed {
    > h5 {
        color:red;
        font-size: 16px;
    }
    > h5 > strong {
        color: black;
    }
    
}
._spinner{
    position: relative;
    padding: 1.3em 0 0 4em;
    float: right;
}


html, body{
    height: 100%;
    min-width: 100%;
    background: red;
    position: fixed;
}
// body{
    
//     background-image: linear-gradient(to bottom, #0097dc 0%, #00558c 100%);
//     background-repeat: repeat-x;
//     min-height: 100%;
//     min-width: 100%;
// }